<template>
  <div class="row">
    <b-link to="/scan/manual" class="col-12 col-lg-3 c g item">
      <div class="card card-body text-center">
        <img src="../assets/images/manual.png" alt="" />
        <p>الفحص يدوياً</p>
      </div>
      <b-link
        to="/scan/bulk"
        class="btn btn-block btn-outline-secondary btn-sm"
        style="border-radius: 0px"
      >
        <img src="../assets/images/list.png" alt="" style="width: 16px" />
        التحضير الجماعي للمعلمين
      </b-link>
    </b-link>
    <b-link to="/scan/manual?barcode" class="col-12 col-lg-3 c g item">
      <div class="card card-body text-center">
        <img src="../assets/images/barcode.png" alt="" />
        <p>الفحص بالباركود</p>
      </div>
    </b-link>
    <b-link to="/fingerprint" class="col-12 col-lg-3 c g item">
      <div class="card card-body text-center">
        <img src="../assets/images/fingerprint.png" alt="" />
        <p>الفحص بالبصمة</p>
      </div>
    </b-link>
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCardText,
    BLink,
  },
};
</script>

<style>
</style>
